define('modules/faqs/accordion/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.livequery", "jquery.mousewheel.min", "jquery.jscrollpane.min"], function (module, config, $, util, templates) {
    return new module({
        name: 'faqs.accordion',
        selectors: '.faqWrap',
        remoteData: [(config.urls.faqs)],

        config: {},

        initialize: function (data) {
            this.$container = $(this.getSelectors());
            this.data = (data || this.data);
            this.renderTemplates(this.data);
            this.faqClickHandlers();
        },

        onRender: function () {
            //this is handling opening the homepage without #home in the url
            util.setVisibleInterval(this.$container, $.proxy(this.initJSP, this), 2000);
        },

        methods: {
            renderTemplates: function (data) {
                $.each(data, function (i, r) {
                    data[i].total = data.length; // TODO - Not necessary, we can access parent context length directly in handlebars tpl via dot notation
                });
                this.$container.append(templates.faqsaccordion({
                    records: data.sort(function (a, b) {
                        return a.id - b.id;
                    })
                }));
            },
            initJSP: function () {
                
                // var jspApi = $(this.getSelectors()).find('.scroll-pane').data('jsp');
                var jspScrollSpeed;
                
                if ($('html').hasClass('mac')) {
                    jspScrollSpeed = 2;
                }
                else {
                	// fallback/default
                	jspScrollSpeed = 40;
                }

                if ($(window).width() < 768) {

                    //mobile stuff
                    if (this.jspApi) {
                        this.jspApi.destroy();
                        this.jspApi = null;
                    }
                } else {

                    //desktop stuff
                    if (!this.jspApi) {

                        this.jspApi = this.$container.find('.scroll-pane').jScrollPane(
                            {
                                showArrows: true,
                                verticalGutter: 0,
                                verticalDragMinHeight: 40,
                                verticalDragMaxHeight: 40,
                                mouseWheelSpeed: jspScrollSpeed,
                                verticalArrowPositions: 'split',
                                horizontalArrowPositions: 'split',
                                animateScroll: false
                            }
                        ).data('jsp');
                    };
                };
            },
            faqClickHandlers: function () {

                //this module is reworked and should be merged with the master branch - the functionality is clearer and more straightforward

                // because the height adjusts when a panel is opened
                // we need to reinit the scroll pane
                this.$container.on('click', '.panel > a', $.proxy(function (event) {

                    var $target = $(event.target);

                    if($target.is(':not(a)')) {
                        $target = $target.closest('a');
                    };

                    var collapsableSibling = $target.next('.panel-collapse');

                    $target.toggleClass('opened');
                    collapsableSibling.stop().slideToggle(300);

                    if ($(window).width() > 768) {
                        if (this.jspApi) {

                            setTimeout($.proxy(function () {
                                this.jspApi.reinitialise();
                            }, this), 370);
                        };
                    };
                }, this));

                $(window).on('resize', $.proxy(function() {
                    if (this.$container.closest('.pageContainer').hasClass('active')) {
                        this.initJSP();
                    };
                }, this));
                
                // initial detection show hide learn more button
                if(!this.$container.find('.panel.mobile-hidden').length) {
					$('.faq-mobile-show-more').addClass('hidden');
				};

                this.$container.on('click', '.faq-mobile-show-more', $.proxy(function (event) {

                    var $target = $(event.target);

                    if($target.is(':not(.faq-mobile-show-more)')) {
                        $target = $target.closest('.faq-mobile-show-more');
                    };

                    this.$container.find('.panel.mobile-hidden:lt(5)').removeClass('mobile-hidden');

                    if(!this.$container.find('.panel.mobile-hidden').length) {
                        $target.addClass('hidden');
                    };
                }, this));
            }
        }
    });
});

